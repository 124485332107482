import React, { ReactElement, useState, useCallback, ChangeEvent } from "react";
import Modal from "./Modal";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { exportRecommendationsToCsv } from "../../lib/utils";

interface IExportDialogProps {
  dataToExport: object[];
  options: string[];
  parentSelector: () => HTMLBodyElement | HTMLDivElement;
}

export default function ExportDialog({
  dataToExport,
  options,
  parentSelector,
}: IExportDialogProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);
  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);

  const [optionsToExport, setOptionsToExport] = useState(options);

  const addOrRemoveOption = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.value;
    _.includes(optionsToExport, field)
      ? setOptionsToExport(_.without(optionsToExport, field))
      : setOptionsToExport([...optionsToExport, field]);
  };

  const _parentSelector =
    parentSelector || (() => document.getElementById("recommendations-comparison"));

  return (
    <div>
      <button
        className="export-trigger text-whoCovid-100 cursor-pointer tailwind-hidden md:block"
        onClick={openModal}
      >
        {t("labels.export")}
      </button>
      <Modal
        closeButton={false}
        isOpen={isOpen}
        onClose={closeModal}
        parentSelector={_parentSelector}
      >
        <div>
          <h1 className="text-center text-whoCovid-100">{t("labels.export")}</h1>
          <div className="text-gray-200 text-center">{t("app.exportDisclaimer")}</div>
          <ul className="export-fields">
            {_.map(options, (option) => {
              return (
                <li key={option} className="my-1 hover:bg-gray-100 cursor-pointer">
                  <label className="p-1 hover:bg-gray-100 cursor-pointer inline-block w-full">
                    <input
                      className="inline-block mt-1"
                      type="checkbox"
                      value={option}
                      checked={_.includes(optionsToExport, option)}
                      onChange={addOrRemoveOption}
                    />
                    <span className="inline-block ml-2">{t(`labels.${option}`)}</span>
                  </label>
                </li>
              );
            })}
          </ul>
          <div className="flex flex-row items-center">
            <button
              onClick={closeModal}
              className="flex-grow mx-1 px-5 py-1 bg-gray-100 hover:opacity-50"
            >
              {t("labels.cancel")}
            </button>
            <button
              className={`${
                _.isEmpty(optionsToExport) ? "opacity-25" : " hover:opacity-50 opacity-100"
              } flex-grow  px-5 py-1 bg-whoCovid-100 text-white mx-1`}
              disabled={_.isEmpty(optionsToExport)}
              onClick={() => exportRecommendationsToCsv(dataToExport, optionsToExport)}
            >
              {t("labels.export")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
