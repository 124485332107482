import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "./ExpandableCard";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import QuestionData from "./QuestionData";
import { questionsHasCommonStrengthAndCertainty } from "../../lib/helpers";

const QuestionsInfo = ({
  question,
  intervention,
  population,
  evidenceTableLink,
  guideline,
  emlLinks,
}) => {
  const { t } = useTranslation();

  const commonStrengthAndCertainty = useMemo(
    () => questionsHasCommonStrengthAndCertainty(question),
    [question]
  );

  const questionsData = useMemo(() => {
    return (
      <QuestionsData
        commonStrengthAndCertainty={commonStrengthAndCertainty}
        interventions={intervention ?? []}
        populations={population ?? []}
        questions={question}
        evidenceTableLink={evidenceTableLink}
        guideline={guideline}
        emlLinks={emlLinks}
      />
    );
  }, [
    commonStrengthAndCertainty,
    question,
    guideline,
    intervention,
    population,
    evidenceTableLink,
    emlLinks,
  ]);

  return !commonStrengthAndCertainty ? (
    <ExpandableCard title={t("labels.comparisons")}>{questionsData}</ExpandableCard>
  ) : (
    questionsData
  );
};

QuestionsInfo.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape),
  populations: PropTypes.arrayOf(populationShape),
  questions: PropTypes.arrayOf(questionShape),
  evidenceTableLink: PropTypes.string,
  emlLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

QuestionsInfo.defaultProps = {
  commonStrengthAndCertainty: true,
  evidenceTableLink: null,
  interventions: [],
  populations: [],
  questions: [],
  emlLinks: [],
};

const QuestionsData = ({
  commonStrengthAndCertainty,
  evidenceTableLink,
  guideline,
  interventions,
  populations,
  questions,
  emlLinks,
}) => {
  return (
    <div>
      {_.map(questions, (question) => (
        <QuestionData
          commonStrengthAndCertainty={commonStrengthAndCertainty}
          interventions={interventions}
          key={question["@id"]}
          populations={populations}
          question={question}
          evidenceTableLink={evidenceTableLink}
          guideline={guideline}
          emlLinks={emlLinks}
        />
      ))}
    </div>
  );
};

QuestionsData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape),
  populations: PropTypes.arrayOf(populationShape),
  questions: PropTypes.arrayOf(questionShape),
  evidenceTableLink: PropTypes.string,
  emlLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

QuestionsData.defaultProps = {
  commonStrengthAndCertainty: true,
  evidenceTableLink: null,
  interventions: [],
  populations: [],
  questions: [],
  emlLinks: [],
};

export default QuestionsInfo;
