import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import LargeSpinner from "./components/common/LargeSpinner";
import Header from "./components/Header";
import PageLayout from "./components/layouts/PageLayout";
import About from "./components/pages/About";
import Glossary from "./components/pages/Glossary";
import Grid from "./components/pages/Grid";
import GuidelinePage from "./components/pages/GuidelinePage";
import AdditionalGuidance from "./components/pages/AdditionalGuidance";
import MainPage from "./components/pages/MainPage";
import RecommendationPage from "./components/pages/RecommendationPage";
import RecommendationsPage from "./components/pages/RecommendationsPage";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-49400080-12");
}

export const AppContent = () => {
  const { t } = useTranslation("");
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return (
    <div className=" min-h-screen overflow-hidden w-full m-0 p-0 bg-right font-arial">
      <div className="min-h-screen overflow-hidden m-0 p-0 bg-left">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("app.title")}</title>
          <meta name="description" content={t("app.aboutShort")} />
          <meta name="keywords" content={t("app.keywords")} />
          <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
        </Helmet>
        <Switch>
          <>
            <Header />
            <Route exact path="/">
              <MainPage />
            </Route>
            <Route exact path="/recommendations">
              <RecommendationsPage />
            </Route>
            <Route exact path="/grid">
              <Grid />
            </Route>
            <Route exact path="/glossary">
              <PageLayout>
                <Glossary />
              </PageLayout>
            </Route>
            <Route path="/guideline/:id">
              <PageLayout>
                <GuidelinePage />
              </PageLayout>
            </Route>
            <Route path="/recommendation/:id">
              <RecommendationPage />
            </Route>
            <Route exact path="/about">
              <PageLayout>
                <About />
              </PageLayout>
            </Route>
            <Route exact path="/guidance-on-implementation">
              <PageLayout>
                <AdditionalGuidance />
              </PageLayout>
            </Route>
          </>
        </Switch>
      </div>
    </div>
  );
};

function App() {
  const { ready } = useTranslation("", { useSuspense: false });

  // wait until translations are loaded
  return ready ? (
    <Router>
      <AppContent />
    </Router>
  ) : (
    <LargeSpinner />
  );
}

export default App;
