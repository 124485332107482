import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetApi } from "../../lib/api";
import { parseTable } from "../../lib/helpers";
import Box from "../common/Box";
import LargeSpinner from "../common/LargeSpinner";
import Search from "../common/Search";

type TRow = {
  title: string;
  fullReference: string;
  publicationDate: string;
  url: string;
  ccitcPp: string;
};

interface ITableRow {
  row: TRow;
}

const TableRow = ({ row }: ITableRow) => {
  const { t } = useTranslation();

  return (
    <div className="mb-5 text-xs">
      <h1 className="font-transat font-semibold text-base mb-2 break-all">{row.title}</h1>
      <div className="flex flex-col md:flex-row">
        <label className="font-semibold mr-2 flex-shrink-0">{t("labels.fullReference")}:</label>
        <div className="break-all">{row.fullReference}</div>
      </div>
      <div className="flex flex-col md:flex-row">
        <label className="font-semibold mr-2 flex-shrink-0">{t("labels.pubDate")}:</label>
        <div className="break-all">{row.publicationDate}</div>
      </div>
      {!_.isEmpty(row.url.trim()) && (
        <div className="flex flex-col md:flex-row">
          <label className="font-semibold mr-2 flex-shrink-0">{t("labels.url")}:</label>
          <div className="break-all">
            <a
              className="text-whoCovid-100 cursor-pointer break-all"
              href={row.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.url}
            </a>
          </div>
        </div>
      )}
      <div className="gradient-separator" />
    </div>
  );
};

const GuildelinesOnChangeOfCareTable = ({ table }: { table: string }) => {
  const parsedTable = useMemo(() => parseTable(table), [table]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredRows = _.filter(parsedTable, (row) => {
    const sq = searchQuery.toLowerCase();

    return (
      (row as TRow).title.toLowerCase().indexOf(sq) !== -1 ||
      (row as TRow).url.toLowerCase().indexOf(sq) !== -1 ||
      (row as TRow).publicationDate.toLowerCase().indexOf(sq) !== -1 ||
      (row as TRow).fullReference.toLowerCase().indexOf(sq) !== -1
    );
  });

  return (
    <div>
      <div className="mb-12 additional-guidance-search-container">
        <Search searchQuery={searchQuery} onSearch={setSearchQuery} />
      </div>
      {_.map(filteredRows, (row, index) => (
        <TableRow row={row as TRow} key={index} />
      ))}
    </div>
  );
};

const AdditionalGuidance = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/additionalGuidance`);

  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-whoCovid-100 font-normal`}>
            {t("labels.guidanceOnImplementation")}
          </h1>
        </div>
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            {isLoading ? (
              <LargeSpinner />
            ) : (
              // @ts-ignore
              <GuildelinesOnChangeOfCareTable table={_.first(data)?.body} />
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default AdditionalGuidance;
