import { map } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import cihrLogo from "../../assets/cihr-logo.svg";
import evidencePrimeLogo from "../../assets/evidence-prime-logo.svg";
import mcMasterLogo from "../../assets/mcmaster-logo.svg";
import whoLogo from "../../assets/who-main-page-logo.svg";
import {
  cochraneCanadaLink,
  covidLink,
  evidenceprimeLink,
  ginLink,
  mcMasterLink,
  niphLink,
} from "../../lib/constants";
import Box from "../common/Box";
import { Team } from "../Team";

const partners = [
  {
    type: "image",
    logo: whoLogo,
    title: "WHO",
    key: "who",
  },
  {
    type: "image",
    logo: mcMasterLogo,
    title: "McMaster University",
    key: "mcmaster",
  },
  {
    type: "image",
    logo: cihrLogo,
    title: "Canadian Institutes of Health Research",
    key: "cihr",
  },
  {
    type: "image",
    logo: evidencePrimeLogo,
    title: "Evidence Prime",
    key: "evidence-prime",
  },
];

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-whoCovid-100 font-normal`}>
            {t("labels.about")}
          </h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4">
            <Trans i18nKey="app.about">
              <p className="mb-4">
                The WHO-COVID19 catalogue of technical guidance collates recommendations related to
                the clinical, public health and health policy management of COVID-19 issued by WHO
                since January 2020. The catalogue contains the most up-to-date technical products
                including Guidelines, Interim Guidance, Rapid Advice Guidance and other technical
                products including actionable statements through to September 1st, 2021, and all
                Guidelines thereafter. For a full list of all WHO documents related to COVID-19,
                please visit the WHO website.
              </p>
              <p className="mb-4">
                This map allows decision-makers to identify relevant recommendations for their
                question of interest. One of the primary goals of this RecMap is to transparently
                report a collation of all evidence-based WHO COVID-19 recommendations, good practice
                statements and additional guidance developed by global context experts and published
                online. This map serves to promote awareness across research professionals
                internationally and allows for guidance to be contextualized to inform local,
                provincial, regional and jurisdictional decision-making.
              </p>
              <p className="mb-4">
                We use the broadly accepted population, interventions, comparators, outcomes and
                settings (PICOS) to map and catagorize recommendations. All recommendations are
                accompanied by a description of their PICO elements and links to interactive Summary
                of Findings (SoF) tables, and Evidence to Decision tables (EtDs). This context
                specific information appears when users search for content within specific sections
                of the map.
              </p>
              <p className="mb-4">
                This Recommendations Map is a product of the collaboration between the World Health
                Organization, the World Health Organization Collaborating Center for Infectious
                Diseases, Research Methods and Recommendations at{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={mcMasterLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  McMaster University
                </a>
                ,{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={cochraneCanadaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cochrane Canada
                </a>
                ,{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={evidenceprimeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Evidence Prime
                </a>
                , the{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={niphLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Norwegian Institute of Public Health
                </a>{" "}
                , the{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={ginLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines International Network
                </a>{" "}
                and many other institutions or organizations. More details on the process and full
                list of contributors is available on{" "}
                <a
                  className="text-whoCovid-100 hover:opacity-50"
                  href={covidLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://covid19.recmap.org
                </a>
              </p>
              <p className="mb-4">
                This platform is free to access. Exporting of information or data requires written
                permission from the developers. WHO staff can export information for internal
                purposes without permission.
              </p>
            </Trans>
          </div>
        </Box>
      </div>
      <div className="w-full max-w-screen-lg relative mx-auto mb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-whoCovid-100 font-normal`}>
            {t("labels.disclaimer")}
          </h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4 flex flex-row flex-wrap about-page--partners">
            <Trans i18nKey="app.disclaimer">
              <p className="mb-2">
                Please read this disclaimer ("disclaimer") carefully before using the COVID19
                Recommendations.
              </p>
              <br />
              <p>
                We do not take responsibility for the accuracy or completeness of the content
                contained within this website. Although reasonable efforts and care have been
                applied in its preparation, we assume no liability or responsibility for errors or
                omissions and consulting the original source of information may be required.
                Recommendations may be updated or changed as the research evidence and guideline
                literature accumulate. The information provided within this website is not intended
                as a substitute for professional advice.
              </p>
            </Trans>
          </div>
        </Box>
      </div>
      <div className="w-full max-w-screen-lg relative mx-auto mb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-whoCovid-100 font-normal`}>
            {t("labels.partners")}
          </h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4 flex flex-row flex-wrap about-page--partners justify-center align-center">
            {map(partners, ({ type, logo, title, key }) =>
              type === "image" ? (
                <img
                  src={logo}
                  alt={title}
                  title={title}
                  key={key}
                  className={`h-15 mb-6 ${key}`}
                />
              ) : (
                <span key={key} className={`h-15 mb-6 ${key}`}>
                  {title}
                </span>
              )
            )}
          </div>
        </Box>
      </div>
      <Team />
    </div>
  );
};

export default About;
