import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import InstructionModal from "../common/InstructionModal";
import WhatWeAreCurrentlyWorkingOn from "../common/WhatWeAreCurrentlyWorkingOn";

const WhatWeAreCurrentlyWorkingOnModal = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => setModalOpen(false), []);

  return (
    <div className="text-right text-whoCovid-100">
      <button onClick={() => setModalOpen(true)} className="hover:opacity-50">
        {t("labels.what_we_are_currently_working_on")}
      </button>
      <InstructionModal
        isOpen={modalOpen}
        onClose={closeModal}
        title={t("labels.what_we_are_currently_working_on")}
      >
        <WhatWeAreCurrentlyWorkingOn contentClassName="text-left" />
      </InstructionModal>
    </div>
  );
};

export default WhatWeAreCurrentlyWorkingOnModal;
