import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <button
      onClick={() => (history.length > 2 ? history.goBack() : history.push("/recommendations"))}
      className="btn-page-back font-semibold py-1 px-8 hover:opacity-75"
    >
      {t("actions.back")}
    </button>
  );
};

export default BackButton;
