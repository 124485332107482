import PropTypes from "prop-types";
import React, { FC, ReactNode } from "react";
import glossaryIcon from "../../assets/glossary-icon.svg";
import gridIcon from "../../assets/grid-icon.svg";
import listIcon from "../../assets/list-icon.svg";
import searchIcon from "../../assets/search-icon.svg";

const ICONS = {
  search: searchIcon,
  list: listIcon,
  grid: gridIcon,
  glossary: glossaryIcon,
};

type SectionKey = "search" | "list" | "grid" | "glossary";

interface IGuideSection {
  sectionKey: SectionKey;
  children: ReactNode;
}

const GuideSection: FC<IGuideSection> = ({ sectionKey, children }) => {
  return (
    <div className="flex-grow text-center mb-24 w-full px-16">
      <img style={{ width: "119px" }} className="mx-auto" src={ICONS[sectionKey]} alt="" />
      <div className="mt-3">{children}</div>
    </div>
  );
};

GuideSection.propTypes = {
  children: PropTypes.node.isRequired,
  sectionKey: PropTypes.oneOf<SectionKey>(["search", "list", "grid", "glossary"]).isRequired,
};

export default GuideSection;
