import _, { trim } from "lodash";
import PropTypes from "prop-types";
import React, { createRef, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  guidelineShape,
  interventionShape,
  noteShape,
  populationShape,
  questionShape,
  referenceShape,
} from "../../lib/data_shapes";
import AdaptedTooltip from "../common/AdaptedTooltip";
import AdolopmentForm from "../common/AdolopmentForm";
import BackButton from "../common/BackButton";
import QuestionsInfo from "../common/QuestionsInfo";
import Coi from "./Coi";
import Conclusions, { SECTIONS_TO_BE_SEPARATED } from "./Conclusions";
import EtDPrintout from "./EtDPrintout";
import ISof from "./ISof";
import Notes from "./Notes";
import RecommendationHeader from "./RecommendationHeader";
import SourceOfRecommendation from "./SourceOfRecommendation";
import SummaryOfChoices from "./SummaryOfChoices";
import WhatWeAreCurrentlyWorkingOnModal from "./WhatWeAreCurrentlyWorkingOnModal";

const SECTIONS = [
  "recommendationHeader",
  "questionsInfo",
  "whatWeAreCurrentlyWorkingOn",
  "conclusions",
  "implementationConsiderationsOrRemarks",
  "summaryOfChoices",
  "isof",
  "iEtd",
  "coi",
  "sourceOfRecommendation",
  "notes",
];

const parseDocument = (props) => {
  const {
    diagnostic,
    emlLink,
    etdPrintout,
    evidenceTableLink,
    gps,
    guideline,
    informal,
    intervention,
    isofData,
    notes,
    onRequestAdolopment,
    population,
    question,
    recommendation,
    recommendationIntentWHO,
    recommendationFormality,
    references,
    reportedAsGPS,
    t,
  } = props;

  if (_.isEmpty(question)) return {};

  const [conclusionsSectionsOrder, implementationConsiderationsOrRemarksOrder] = _.partition(
    recommendation.conclusions?.sectionsOrder,
    (section) => !_.includes(SECTIONS_TO_BE_SEPARATED, section)
  );

  const conclusionsSections = recommendation.conclusions?.sections;
  const conclusionsHasAnyValue = _.some(conclusionsSections, (section) => !_.isEmpty(section));

  const sectionComponents = {};
  const sectionBlocks = [];
  const sectionRefs = {};
  SECTIONS.forEach((section) => {
    switch (section) {
      case "recommendationHeader":
        sectionComponents[section] = (
          <RecommendationHeader
            guideline={guideline}
            gradeCertaintyOfEvidence={_.first(question).gradeCertaintyOfEvidence}
            intents={recommendationIntentWHO}
            gps={gps}
            informal={informal}
            recommendation={recommendation}
            recommendationFormality={recommendationFormality}
            onRequestAdolopment={onRequestAdolopment}
            irisLink={guideline.sourceDocumentLink}
          />
        );
        sectionBlocks.push(section);
        break;
      case "questionsInfo":
        sectionComponents[section] = (
          <QuestionsInfo
            guideline={guideline}
            question={question}
            intervention={intervention}
            population={population}
            evidenceTableLink={evidenceTableLink}
            emlLinks={emlLink}
          />
        );
        break;
      case "whatWeAreCurrentlyWorkingOn":
        sectionComponents[section] = <WhatWeAreCurrentlyWorkingOnModal />;
        break;
      case "conclusions":
        if (!_.isEmpty(conclusionsSectionsOrder) && conclusionsHasAnyValue) {
          const sectionKey = gps ? "goodPracticeStatementCriteria" : "additionalInformation";
          const conclusionsToggleRef = createRef();
          sectionComponents[sectionKey] = (
            <Conclusions
              gps={gps}
              reportedAsGPS={reportedAsGPS}
              sections={conclusionsSections}
              sectionsOrder={conclusionsSectionsOrder}
              toggleRef={conclusionsToggleRef}
            />
          );
          sectionBlocks.push(sectionKey);
          sectionRefs[sectionKey] = conclusionsToggleRef;
        }
        break;
      case "implementationConsiderationsOrRemarks":
        const sectionKey = "additionalInformation";

        const { implementationConsiderationsOrRemarks } = conclusionsSections || {};
        if (
          !_.isEmpty(implementationConsiderationsOrRemarksOrder) &&
          !_.isEmpty(implementationConsiderationsOrRemarks)
        ) {
          const additionalInformationToggleRef = createRef();
          sectionComponents[sectionKey] = (
            <Conclusions
              gps={gps}
              title={t("labels.additionalInformation")}
              sections={recommendation.conclusions.sections}
              sectionsOrder={implementationConsiderationsOrRemarksOrder}
              toggleRef={additionalInformationToggleRef}
            />
          );
          sectionBlocks.push(sectionKey);
          sectionRefs[sectionKey] = additionalInformationToggleRef;
        }
        break;
      case "summaryOfChoices":
        const { sections: assessmentSections } = recommendation.assessmentSummary;
        const hasAnyValue = _.some(assessmentSections, (section) => !_.isEmpty(section));
        if (hasAnyValue) {
          const sofToggleRef = createRef();
          sectionComponents[section] = (
            <SummaryOfChoices
              assessmentSummary={recommendation?.assessmentSummary}
              toggleRef={sofToggleRef}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = sofToggleRef;
        }
        break;
      case "isof":
        if (!_.isEmpty(isofData)) {
          const isofToggleRef = createRef();
          sectionComponents[section] = (
            <ISof toggleRef={isofToggleRef} isofData={isofData} isDiagnostic={diagnostic} />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = isofToggleRef;
        }
        break;
      case "iEtd":
        if (!_.isEmpty(etdPrintout)) {
          const ietdToggleRef = createRef();
          sectionComponents[section] = (
            <EtDPrintout etdPrintout={etdPrintout} toggleRef={ietdToggleRef} />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = ietdToggleRef;
        }
        break;
      case "coi":
        if (!_.isEmpty(recommendation.coi)) {
          const coiToggleRef = createRef();
          sectionComponents[section] = <Coi coi={recommendation.coi} toggleRef={coiToggleRef} />;
          sectionBlocks.push(section);
          sectionRefs[section] = coiToggleRef;
        }
        break;
      case "sourceOfRecommendation":
        if (!_.isEmpty(guideline)) {
          const sourceOfRecommendationToggleRef = createRef();
          sectionComponents[section] = (
            <SourceOfRecommendation
              toggleRef={sourceOfRecommendationToggleRef}
              guideline={guideline}
            />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = sourceOfRecommendationToggleRef;
        }
        break;
      case "notes":
        if (!_.isEmpty(notes) && !_.isEmpty(references)) {
          const notesToggleRef = createRef();
          sectionComponents[section] = (
            <Notes notes={notes} references={references} toggleRef={notesToggleRef} />
          );
          sectionBlocks.push(section);
          sectionRefs[section] = notesToggleRef;
        }
        break;
      default:
        break;
    }
  });

  return {
    sectionComponents,
    sectionBlocks,
    sectionRefs,
  };
};

const Recommendation = ({ document: recDoc }) => {
  const { t } = useTranslation();
  const scrollTo = useCallback(
    (section) => () => {
      const sectionName = _.camelCase(section.replace("section_", ""));
      if (sectionRefs[sectionName]) {
        sectionRefs[sectionName].current.openAndScrollTo(section);
      } else {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
      }
    },
    []  // eslint-disable-line react-hooks/exhaustive-deps
  );
  const { sectionComponents, sectionBlocks, sectionRefs } = useMemo(
    () => parseDocument({ ...recDoc, t, onRequestAdolopment: scrollTo("adolopment-form") }),
    [t, recDoc, scrollTo]
  );

  const { informal, gps, "@id": id } = recDoc;

  const keywords = useMemo(() => {
    return _.uniq(
      (recDoc.keywords?.[0] || "")
        .split(",")
        .map(trim)
        .concat((recDoc.guideline?.keywords?.[0] || "").split(",").map(trim))
    ).join(", ");
  }, [recDoc]);

  if (_.isEmpty(recDoc.question)) return null;
  return (
    <>
      <Helmet>{!_.isEmpty(keywords) && <meta name="keywords" content={keywords} />}</Helmet>
      <div>
        <div className={"relative overflow-hidden h-full page-content flex flex-col sm:flex-row"}>
          <div className="tailwind-hidden sm:block my-6 mx-3">
            <BackButton />
          </div>
          <div className="flex flex-row px-2 mt-2 mx-auto sm:mt-5 mb-2 max-w-screen-xl h-full">
            <div>
              {_.map(sectionComponents, (section, key) => {
                return (
                  <div key={key} id={`section_${key}`} className="pb-5">
                    {section}
                  </div>
                );
              })}
              {!informal && !gps && (
                <div>
                  <div className="gradient-separator" />
                  <AdolopmentForm recommendationId={id} />
                </div>
              )}
            </div>
            <div>
              <div className="content-blocks ml-6 tailwind-hidden lg:block">
                {_.map(sectionBlocks, (section) => {
                  return (
                    <div
                      className="
                      content-blocks__block
                      hover:opacity-75
                      cursor-pointer
                      block
                      mb-2
                      rounded
                      shadow

                      bg-yellow-800
                      text-center
                      text-whoCovid-100"
                      key={section}
                    >
                      <button
                        className="
                      py-1
                      font-semibold
                      px-2"
                        onClick={scrollTo(`section_${section}`)}
                      >
                        {t(`labels.${section}`)}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          className="tooltip-container"
          type="dark"
          effect="float"
          backgroundColor="#031B49"
        />
        <AdaptedTooltip />
      </div>
    </>
  );
};

Recommendation.propTypes = {
  document: PropTypes.shape({
    guideline: guidelineShape,
    interventions: PropTypes.arrayOf(interventionShape),
    populations: PropTypes.arrayOf(populationShape),
    question: PropTypes.arrayOf(questionShape),
    notes: PropTypes.arrayOf(noteShape),
    references: PropTypes.arrayOf(referenceShape),
  }).isRequired,
};

export default Recommendation;
