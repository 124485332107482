import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { FIELDS_TO_EXPORT } from "../../lib/constants";
import { useSearchQuery } from "../../lib/custom_hooks";
import Sidebar from "../Sidebar";
import ExportDialog from "../common/ExportDialog";
import FakeLoader from "../common/FakeLoader";
import Search from "../common/Search";
import RecommendationGroupsList from "../recommendation/RecommendationGroupsList";

const RecommendationsPage = () => {
  const initialSearchQuery = useSearchQuery();
  const { t } = useTranslation();
  const { currentFilters, data, isError, isLoading, onChangeFilter, onClearFilters } =
    useRecommendationsFetch("recommendations");

  return (
    <div className="overflow-hidden relative  page-content">
      <div
        className="relative
          h-full w-full  flex flex-col-reverse sm:flex-row justify-end overflow-visible"
      >
        <div className="w-full">
          <div className="w-full bg-white shadow mb-1 relative" style={{ zIndex: 5 }}>
            <div className="flex flex-row items-center w-full max-w-screen-lg mx-auto">
              <div className="flex flex-grow">
                <Search
                  className="inline-block mt-8 mx-3 md:mt-1 mb-2"
                  searchQuery={initialSearchQuery}
                  onSearch={(query) => onChangeFilter("searchQuery", query)}
                />
              </div>
              <div className="mr-4" style={{ zIndex: 6 }}>
                {!isLoading && data && data.recommendations && (
                  <ExportDialog
                    dataToExport={data.recommendations}
                    options={FIELDS_TO_EXPORT}
                    parentSelector={() => document.body}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex-grow w-full max-w-screen-lg mx-auto">
            {isError && <div>{t("errors.fetching_failed")}</div>}
            <FakeLoader isLoading={isLoading} interval={75} />
            {!isLoading && <RecommendationGroupsList recommendations={data?.recommendations} />}
          </div>
        </div>
        {isLoading || _.isEmpty(data) ? null : (
          <Sidebar
            isLoading={isLoading}
            filters={data.filterValues}
            currentFilters={currentFilters}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationsPage;
