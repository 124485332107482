import React, { FC } from "react";

const ExternalLink: FC<{ className?: string; label: string; url: string }> = ({
  className,
  label,
  url,
}) => (
  <a
    className={`py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3 block ${className ?? ""}`}
    href={url}
    rel="noopener noreferrer"
    target="_blank"
  >
    {label}
  </a>
);

export default ExternalLink;
