import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import { isValidLink } from "../../lib/utils";
import Box from "../common/Box";
import Certainty from "../common/Certainty";
import ExternalLink from "../common/ExternalLink";
import InstructionModal from "../common/InstructionModal";
import Intents from "../common/Intents";
import RecommendationIcon from "../common/RecommendationIcon";
import TypeHeader, { RECOMMENDATION_FORMALITIES } from "../common/TypeHeader";
import GuidelineHeader from "./GuidelineHeader";

const RecommendationHeader = ({
  adapted,
  gps,
  gradeCertaintyOfEvidence,
  guideline,
  informal,
  intents,
  irisLink,
  onRequestAdolopment,
  recommendation,
  recommendationFormality,
}) => {
  const { t } = useTranslation();
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const { strength: gradeStrength, direction: recommendationDirection } = recommendation ?? {};

  const recommendationType = useMemo(() => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    return getRecommendationText(t, gradeStrength);
  }, [t, gradeStrength]);

  const closeInstructionModal = useCallback(() => {
    setShowInstructionModal(false);
  }, [setShowInstructionModal]);

  const recommendationBoxBgClass = useMemo(() => {
    switch (recommendationFormality) {
      case "gps":
        return "bg-yellow-800";
      case "informal":
        return "bg-purple-800";
      case "formal":
        return "bg-teal-800";
      default:
        return "";
    }
  }, [recommendationFormality]);

  const agreeScoreBgClass = useMemo(() => {
    switch (recommendationFormality) {
      case "gps":
        return "bg-yellow-900";
      case "informal":
        return "bg-purple-900";
      case "formal":
        return "bg-teal-900";
      default:
        return "";
    }
  }, [recommendationFormality]);

  if (!recommendation) return null;

  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="flex-grow">
          <TypeHeader
            adapted={adapted}
            className="text-whoCovid-100 mb-3 mt-1"
            recommendationFormality={recommendationFormality}
            type="recommendation"
          />
        </div>
        <div>
          <button
            className="text-whoCovid-100 hover:opacity-50"
            onClick={() => setShowInstructionModal(true)}
          >
            <span className="tailwind-hidden md:block">
              {t("labels.on_this_page_you_can_find")}
            </span>
            <span className="block md:hidden info-tooltip-icon" />
          </button>
        </div>
        <InstructionModal
          isOpen={showInstructionModal}
          onClose={closeInstructionModal}
          title={t("labels.on_this_page_you_can_find")}
        >
          <div dangerouslySetInnerHTML={{ __html: t("detailsOfRecommendationPage.description") }} />
        </InstructionModal>
      </div>
      <Box className={recommendationBoxBgClass}>
        <GuidelineHeader guideline={guideline} />
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow p-5">
            <Intents intents={intents} />
            <div className="mr-6" dangerouslySetInnerHTML={{ __html: recommendation.value }} />
            <div className="mt-3">
              {isValidLink(irisLink) ? (
                <ExternalLink className="text-whoCovid-100 -mx-3" label={irisLink} url={irisLink} />
              ) : (
                irisLink
              )}
            </div>
          </div>
          <div className={`flex flex-col text-whoCovid-100 mt-3 md:mt-0 p-5 ${agreeScoreBgClass}`}>
            <div className="strength-certainty-column text-blue-100 flex-grow">
              {gradeCertaintyOfEvidence && (
                <div className="bordered-info-box" data-tip={t("tooltips.certainty_of_evidence")}>
                  <div className="font-semibold">{t("recommendation.certainty_of_evidence")}</div>
                  <Certainty value={gradeCertaintyOfEvidence} />
                </div>
              )}
              {recommendation.strength && (
                <div className="bordered-info-box" data-tip={t("tooltips.strength")}>
                  <div className="font-semibold">{t("recommendation.recommendation_strength")}</div>
                  <div>
                    <RecommendationIcon
                      textClasses="font-normal"
                      recommendationText={recommendationText}
                      recommendationType={recommendationType}
                    />
                  </div>
                </div>
              )}
            </div>
            {!informal && !gps && (
              <button className="bg-blue-100 text-white py-1 mt-6" onClick={onRequestAdolopment}>
                {t("labels.requestAdolopment")}
              </button>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

RecommendationHeader.propTypes = {
  recommendation: PropTypes.object.isRequired,
  guideline: guidelineShape.isRequired,
  gradeStrength: PropTypes.string,
  informal: PropTypes.bool,
  gps: PropTypes.bool,
  intents: PropTypes.arrayOf(PropTypes.string),
  recommendationFormality: PropTypes.oneOf(RECOMMENDATION_FORMALITIES).isRequired,
};

RecommendationHeader.defaultProps = {
  gradeStrength: "",
  gps: false,
  informal: false,
  intents: [],
};

export default RecommendationHeader;
