import PropTypes from "prop-types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const WhatWeAreCurrentlyWorkingOn: FC<{ contentClassName?: string }> = ({ contentClassName }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mx-3">
        <div className="font-semibold text-center text-whoCovid-100 pb-3">
          {t("labels.contextualization")}
        </div>
      </div>
    </div>
  );
};

WhatWeAreCurrentlyWorkingOn.propTypes = {
  contentClassName: PropTypes.string,
};

WhatWeAreCurrentlyWorkingOn.defaultProps = {
  contentClassName: undefined,
};

export default WhatWeAreCurrentlyWorkingOn;
