import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import InstructionModal from "./InstructionModal";

const AdolopmentInstruction = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(
    (open) => () => {
      setShowModal(open);
    },
    [setShowModal]
  );

  return (
    <React.Fragment>
      <span onClick={toggleModal(true)} className="info-tooltip-icon cursor-pointer" />
      <InstructionModal
        isOpen={showModal}
        onClose={toggleModal(false)}
        title={t("labels.adolopment")}
      >
        <Trans i18nKey="detailsOfRecommendationPage.adolopmentInstruction">
          <p className="mb-2">
            Adolopment is an approach to contextualization of guidelines that is defined as the need
            for dialogue and formal consideration of the local best available evidence and criteria
            for adoption, adaption, or de novo creation of recommendations from an existing
            trustworthy guideline to a national, local, or other level. Considerations should be
            made to decide whether these recommendations are right for that setting and modifying or
            adding to the recommendations to optimise their implementation using structured and
            transparent processes{" "}
            <a
              className="text-whoCovid-100"
              target="blank"
              href="https://www.thelancet.com/journals/lanpub/article/PIIS2468-2667(22)00057-3/fulltext"
              rel="noopener noreferer"
            >
              (Schünemann et al. Lancet Public Health 2022)
            </a>{" "}
            allows the adoption of an existing guideline recommendation, its adaptation, or de novo
            development of an important recommendation when no recommendation exists in the source
            guideline.
          </p>
          <p className="mb-2">
            The Adolopment approach utilizes Evidence to Decision (EtD) frameworks, a systematic and
            transparent approach to make well informed healthcare choices. This allows
            decision-makers to build on previous work instead of repeating much of the laborious
            basic work while considering adoption of the global recommendations to their specific
            country context.
          </p>
          <p className="mb-2">
            Adolopment ensures inclusion of local or regional evidence to optimize relevance and
            uptake and implementation by local authorities.
          </p>
        </Trans>
      </InstructionModal>
    </React.Fragment>
  );
};

export default AdolopmentInstruction;
