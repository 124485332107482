import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { supportLink } from "../../lib/constants";
import { linkToKnowledgebaseAdolopment } from "../../lib/utils";

type Props = {};

const AdolopmentDescription = (props: Props) => {
  return (
    <div className="mb-2">
      <div>
        <Trans i18nKey="detailsOfRecommendationPage.aboutAdolopment">
          This functionality allows your group to adolop guidelines that you find here using
          the GRADE adolopment process (a neologism for adopting, adapting and de novo creation of
          recommendations from source guidelines) for recommendations available on this portal. By{" "}
          <a href={`mailto:${supportLink}`} className="text-whoCovid-100 hover:underline">
            submitting the request
          </a>
          , you will be contacted by our staff to support the project. You can state your area of
          interest or pick particular recommendations available on the{" "}
          <Link className="text-whoCovid-100 hover:underline" to="recommendations">
            recommendations list
          </Link>{" "}
          and the{" "}
          <Link className="text-whoCovid-100 hover:underline" to="grid">
            recommendations map
          </Link>
          .
        </Trans>
      </div>
      <div className="mt-2">
        <Trans i18nKey="detailsOfRecommendationPage.learnAboutAdolopment">
          You can learn more about the adolopment process in our{" "}
          <a
            className="text-whoCovid-100 hover:underline"
            target="_blank"
            rel="noreferrer noopener"
            href={linkToKnowledgebaseAdolopment()}
          >
            knowledgebase
          </a>
          .
        </Trans>
      </div>
    </div>
  );
};

export default AdolopmentDescription;
