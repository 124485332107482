import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GuidelineLink from "../common/GuidelineLink";
import TypeHeader from "../common/TypeHeader";
import RecommendationCertainty from "./RecommendationCertainty";
import RecommendationSnippet from "./RecommendationSnippet";
import RecommendationStrength from "./RecommendationStrength";

const RecommendationItem = ({ recommendation }) => {
  const {
    adapted,
    "@id": id,
    guideline,
    recommendation: text,
    gradeCertaintyOfEvidence,
    gradeCertaintyOfEvidenceLabel,
    recommendationDirection,
    recommendationFormality,
    gradeStrength,
  } = recommendation;

  const { t } = useTranslation();
  return (
    <div className="recommendation-item bg-white mb-3 last:mb-0 hover:shadow text-sm">
      <Link className="text-gray-800 block p-4" to={`/recommendation/${id}`}>
        <div className="flex flex-row">
          <TypeHeader
            adapted={adapted}
            className="flex-grow"
            recommendationFormality={recommendationFormality}
            size="small"
            type="recommendation"
          />
          <span className="text-whoCovid-100 underline text-xs">{t(`labels.see_more`)}</span>
        </div>
        <div className="block mt-2">
          {guideline && <GuidelineLink guideline={guideline} />}
          <RecommendationSnippet text={text} />
        </div>
        <div className="flex text-2xs">
          <div className="mt-1 flex-grow">
            {gradeCertaintyOfEvidence && (
              <RecommendationCertainty
                noBox
                signSize="small"
                gradeCertaintyOfEvidenceLabel={gradeCertaintyOfEvidenceLabel}
                gradeCertaintyOfEvidence={gradeCertaintyOfEvidence}
              />
            )}
          </div>
          <div className="mt-1">
            {gradeStrength && (
              <>
                <div className="mt-1 font-semibold">
                  {t("recommendation.recommendation_strength")}
                </div>
                <RecommendationStrength
                  noBox
                  gradeStrength={gradeStrength}
                  recommendationDirection={recommendationDirection}
                />
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

RecommendationItem.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

RecommendationItem.defaultProps = {
  guideline: null,
};

export default RecommendationItem;
