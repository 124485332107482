import React, { useState, useReducer } from "react";
import { Trans, useTranslation } from "react-i18next";
import Card from "./Card";
import _ from "lodash";
import { linkToPrivacyPolicy } from "../../lib/constants";
import { sendRequestForAdolopment } from "../../lib/api";
import { isValidEmail } from "../../lib/utils";
import Modal from "./Modal";
import AdolopmentDescription from "./AdolopmentDescription";

const InputField = ({ label, value, onChange }) => {
  return (
    <>
      <label className="block">{label}</label>
      <input
        className="border border-covid-100 w-full rounded p-2"
        type="text"
        value={value}
        onChange={onChange}
      />
    </>
  );
};

const MessageSentModal = ({ isOpen, onClose, message }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} modalSize="small">
      <div>{message}</div>
      <div className="text-center">
        <button
          className="py-1 px-4 w-full mt-3 md:w-64 bg-whoCovid-100 text-white rounded"
          onClick={onClose}
        >
          {t("labels.close")}
        </button>
      </div>
    </Modal>
  );
};

const initialState = {
  name: "",
  email: "",
  message: "",
  privacyPolicy: false,
  validationStatus: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setName":
      return { ...state, name: action.value, validationStatus: "" };
    case "setEmail":
      return { ...state, email: action.value, validationStatus: "" };
    case "setMessage":
      return { ...state, message: action.value, validationStatus: "" };
    case "togglePrivacyPolicy":
      return { ...state, privacyPolicy: !state.privacyPolicy, validationStatus: "" };
    case "setValidationStatus":
      return { ...state, validationStatus: action.message };
    case "restoreInitialState":
      return { ...initialState };
    default:
      return state;
  }
};

const AdolopmentForm = ({ recommendationId }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [sending, setSending] = useState("");

  const sendRequest = () => {
    if (
      _.isEmpty(state.name) ||
      _.isEmpty(state.email) ||
      _.isEmpty(state.message) ||
      !state.privacyPolicy
    ) {
      dispatch({ type: "setValidationStatus", message: t("errors.validationFailed") });
    } else if (!isValidEmail(state.email)) {
      dispatch({ type: "setValidationStatus", message: t("errors.emailValidationFailed") });
    } else {
      setSending("sending");

      sendRequestForAdolopment({
        recommendationId,
        contactPerson: state.name,
        contactEmail: state.email,
        message: state.message,
      })
        .then((res) => {
          if (res && res.status === 200) {
            setSending("messageHasBeenSent");
          } else {
            setSending("sendingMessageFailed");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          dispatch({ type: "restoreInitialState" });
        });
    }
  };

  return (
    <Card className="border mb-12">
      <MessageSentModal
        isOpen={sending === "messageHasBeenSent" || sending === "sendingMessageFailed"}
        onClose={() => setSending("")}
        message={!_.isEmpty(sending) && sending !== "sending" ? t(`messages.${sending}`) : ""}
      />
      <div id="adolopment-form">
        <h1 className="text-whoCovid-100 text-center mt-1 mb-3 text-xl">Adolopment</h1>
        <AdolopmentDescription />
        <div className="mt-6">
          {!_.isEmpty(state.validationStatus) && (
            <div className="mt-2 font-semibold text-center text-red-100">
              {state.validationStatus}
            </div>
          )}
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mr-4">
              <InputField
                label={t("labels.name")}
                onChange={(e) => dispatch({ type: "setName", value: e.target.value })}
                value={state.name}
              />
            </div>
            <div className="flex-grow">
              <InputField
                label={t("labels.email")}
                onChange={(e) => dispatch({ type: "setEmail", value: e.target.value })}
                value={state.email}
              />
            </div>
          </div>
          <div className="mt-3">
            <label className="block">{t("labels.messageLabel")}</label>
            <textarea
              className="w-full border border-covid-100 rounded p-2"
              value={state.message}
              onChange={(e) => dispatch({ type: "setMessage", value: e.target.value })}
            />
          </div>
          <div>
            <div className="text-xs">
              {t("detailsOfRecommendationPage.privacyPolicyDescription")}
            </div>
            <label className="cursor-pointer">
              <input
                className="mr-2"
                type="checkbox"
                checked={state.privacyPolicy}
                onChange={() => dispatch({ type: "togglePrivacyPolicy" })}
              />

              <Trans i18nKey="labels.privacyPolicy">
                I accept{" "}
                <a
                  className="text-whoCovid-100 hover:underline"
                  href={linkToPrivacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Trans>
            </label>
          </div>
          <div className="text-right">
            <button
              className={`py-1 px-4 w-full mt-3 md:mt-0 md:w-64 bg-whoCovid-100 text-white rounded ${
                sending ? "opacity-50" : "opacity-100"
              }`}
              onClick={sendRequest}
              disabled={sending === "sending"}
            >
              {sending === "sending" ? t("labels.sending") : t("labels.sendRequestForAdolopment")}
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AdolopmentForm;
