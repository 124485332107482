import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import whoLogo from "../assets/who-logo-white.svg";
import whoLogoSmall from "../assets/who-white-small-logo.svg";
import ReactTooltip from "react-tooltip";
import HamburgerMenu from "./common/HamburgerMenu";
import gridThumbnail from "../assets/grid-thumbnail.png";
import listThumbnail from "../assets/list-thumbnail.png";
import { isMobile } from "react-device-detect";

interface ILinkWithTooltip {
  linkId: string;
  activeRoute: string | undefined;
}

const LinkWithTooltip: FC<ILinkWithTooltip> = ({ linkId, activeRoute }) => {
  const { t } = useTranslation();
  const navLinkClasses = useCallback(
    (link) => {
      return `top-nav-link text-white mr-4 py-1 ${
        linkId === "grid" ? "tailwind-hidden md:inline-block" : "inline-block"
      } ${activeRoute === link ? "active" : null}`;
    },
    [activeRoute, linkId]
  );
  return (
    <>
      <Link data-tip="" className={navLinkClasses(linkId)} to={`/${linkId}`} data-for={linkId}>
        {t(`recommendations.${linkId}`)}
      </Link>
      {!isMobile && (
        <ReactTooltip
          id={linkId}
          effect="solid"
          className="tooltip-container"
          type="dark"
          place="bottom"
          clickable
          delayShow={300}
          delayHide={500}
          backgroundColor="#031B49"
          getContent={() => (
            <div>
              <Link to={`/${linkId}`}>
                <img
                  src={linkId === "grid" ? gridThumbnail : listThumbnail}
                  alt={t(`recommendations.${linkId}`)}
                  width="200"
                />
              </Link>
            </div>
          )}
        />
      )}
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const activeRoute = useMemo(() => _.first(_.split(_.trimStart(location.pathname, "/"), "/")), [
    location.pathname,
  ]);

  return (
    <header className="z-10 relative h-12 p-2 sm:h-15 flex flex-row items-center shadow">
      <div className="logo flex-grow flex flex-row">
        {location.pathname !== "/" && <Link to="/" className="mobile-back-link block sm:hidden" />}
        <Link className="flex flex-row items-center" to="/">
          <img src={whoLogo} className="h-12 tailwind-hidden sm:block" alt="logo" />
          <img src={whoLogoSmall} className="h-8 block sm:hidden" alt="logo" />
        </Link>
      </div>
      <div className="flex flex-row items-center">
        <nav className="mr-12">
          <LinkWithTooltip linkId="grid" activeRoute={activeRoute} />
          <LinkWithTooltip linkId="recommendations" activeRoute={activeRoute} />
        </nav>
        <HamburgerMenu />
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
