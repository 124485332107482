import PropTypes from "prop-types";
import React, { FC, ReactNode } from "react";
import BackButton from "../common/BackButton";

interface IPageLayout {
  children: ReactNode;
  className?: string;
}
const PageLayout: FC<IPageLayout> = ({ children, className }) => {
  return (
    <div className={`overflow-hidden h-full page-content flex flex-col sm:flex-row ${className}`}>
      <div className="tailwind-hidden sm:block my-6 mx-3">
        <BackButton />
      </div>
      <div className="px-2 mt-2 sm:mt-5 flex-grow max-w-screen-lg mx-auto mb-2">{children}</div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PageLayout.defaultProps = {
  className: "",
};

export default PageLayout;
