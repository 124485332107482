import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import GridBody from "../grid/GridBody";
import GridContainer from "../grid/GridContainer";
import GridFixedColumn from "../grid/GridFixedColumn";
import GridHeader from "../grid/GridHeader";
import GridRow from "../grid/GridRow";
import RMTBody from "./RMTBody";
import RMTHeader from "./RMTHeader";
import RMTLeftColumn from "./RMTLeftColumn";
import FakeLoader from "../common/FakeLoader";

const COLUMN_WIDTH = 122;
const HEADER_HEIGHT = 50;

const RMT = ({
  grid,
  onClick,
  displayMode,
  isLoading,
  highlightedRecommendation,
  colorMap,
  currentIntentSelected,
  onChangeIntentSelected,
}) => {
  const headerRef = useRef(null);
  const fixedColRef = useRef(null);
  const interventionsPopulationRef = useRef(null);
  const interventionsRowRef = useRef(null);
  const gridBodyRef = useRef(null);
  const gridFixedColRef = useRef(null);

  const { rowGroups, columns } = grid;

  const onScroll = (e) => {
    headerRef.current.scrollLeft = e.target.scrollLeft;
    fixedColRef.current.scrollTop = e.target.scrollTop;
  };

  useEffect(() => {
    // Will keep in sync height of the interventions container inside grid, and leftColumn
    // because they are in separate container.
    const interventionsRow = document.getElementById("interventions-row");
    if (interventionsRow) {
      interventionsPopulationRef.current.style.height = `${interventionsRow.clientHeight}px`;
      interventionsRowRef.current.style.height = `${interventionsRow.clientHeight}px`;
      const tableHeight = `calc(100% - ${HEADER_HEIGHT}px - ${interventionsRow.clientHeight}px)`;
      gridBodyRef.current.style.height = tableHeight;
      gridFixedColRef.current.style.height = tableHeight;
    }

    if (currentIntentSelected === "all") {
      interventionsPopulationRef.current.style.height = 0;
      interventionsPopulationRef.current.style.borderBottom = "none";
      interventionsRowRef.current.style.height = 0;
      const tableHeight = `calc(100% - ${HEADER_HEIGHT}px)`;
      gridBodyRef.current.style.height = tableHeight;
      gridFixedColRef.current.style.height = tableHeight;
    }
  }, [
    currentIntentSelected,
    columns,
    interventionsPopulationRef,
    interventionsRowRef,
    gridBodyRef,
  ]);

  const headerWidth = useMemo(() => {
    const columnsCount = columns ? columns.length : 8;
    return `${columnsCount * COLUMN_WIDTH}px`;
  }, [columns]);

  const emptyResults = _.every(rowGroups, (rowGroup) => _.isEmpty(rowGroup.rows));

  return (
    <>
      <GridFixedColumn gridFixedColRef={gridFixedColRef}>
        <RMTLeftColumn
          fixedColRef={fixedColRef}
          rowGroups={rowGroups}
          currentIntentSelected={currentIntentSelected}
          onChangeIntentSelected={onChangeIntentSelected}
          interventionsPopulationRef={interventionsPopulationRef}
        />
      </GridFixedColumn>
      <GridContainer>
        <GridHeader gridHeaderRef={headerRef}>
          <GridRow className="rmt-header-row">
            <RMTHeader
              columnWidth={COLUMN_WIDTH}
              headerWidth={headerWidth}
              currentIntentSelected={currentIntentSelected}
              onChangeIntentSelected={onChangeIntentSelected}
              columns={columns}
              interventionsRowRef={interventionsRowRef}
            />
          </GridRow>
        </GridHeader>
        <FakeLoader isLoading={isLoading} interval={100} />
        <GridBody onScroll={onScroll} gridBodyRef={gridBodyRef}>
          {!isLoading && emptyResults ? (
            <div className="empty-results-body" />
          ) : (
            <RMTBody
              headerWidth={headerWidth}
              rowGroups={rowGroups}
              currentIntentSelected={currentIntentSelected}
              highlightedRecommendation={highlightedRecommendation}
              colorMap={colorMap}
              displayMode={displayMode}
              onClick={onClick}
            />
          )}
        </GridBody>
      </GridContainer>
    </>
  );
};

RMT.propTypes = {};

export default RMT;
