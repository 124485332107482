import PropTypes from "prop-types";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import whoLogo from "../../assets/who-main-page-logo.svg";
import { useSearchQuery } from "../../lib/custom_hooks";
import AdolopmentDescription from "../common/AdolopmentDescription";
import AdolopmentInstruction from "../common/AdolopmentInstruction";
import GuideSection from "../common/GuideSection";
import Search from "../common/Search";
import Footer from "../layouts/Footer";

const LinkToSubpage = ({ text, to, className }) => (
  <Link
    className={`${className} whitespace-no-wrap flex-grow mr-3 mb-3 bg-blue-100 text-16px font-semibold px-12 py-3
      text-center items-center block shadow relative text-white`}
    to={to}
  >
    {text}
  </Link>
);

LinkToSubpage.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkToSubpage.defaultProps = {
  className: "",
};

const MainPage = () => {
  const { t } = useTranslation("");
  const history = useHistory();
  const initialSearchQuery = useSearchQuery();
  return (
    <div className="main-page-container overflow-hidden relative flex flex-col">
      <div className="main-page-container__top flex flex-col flex-grow  ">
        <div className="text-center pt-12">
          <img className="mx-auto w-64" src={whoLogo} alt="COVID19 logo" />
          <div className="app-subtitle max-w-xl text-center mx-auto mb-3 mt-12 text-3xl">
            {t("app.mainPageSubtitle")}
          </div>
        </div>
        <div className="mx-auto px-1 md:px-0 flex flex-col flex-grow">
          <div className="mt-12">
            <Search
              className=" block mx-auto main-page-search-container"
              inputClassName="w-full bg-white main-page"
              searchQuery={initialSearchQuery}
              onSearch={(query) =>
                history.push(
                  `/recommendations?${new URLSearchParams(`searchQuery=${query}`).toString()}`
                )
              }
            />
          </div>
          <div className="flex flex-grow">
            <div className="flex-grow max-w-3xl">
              <div className="mt-6 flex flex-row flex-wrap justify-between w-full">
                <LinkToSubpage
                  className="mr-2 tailwind-hidden md:block"
                  to="/grid"
                  text={t(`recommendations.grid`)}
                />
                <LinkToSubpage to="/recommendations" text={t(`recommendations.recommendations`)} />
              </div>
              <div className="mt-1 flex flex-row flex-wrap justify-center w-full">
                <LinkToSubpage
                  className="mt-1 max-w-sm"
                  to="/guidance-on-implementation"
                  text={t("labels.guidanceOnImplementation")}
                />
              </div>
              <div className="mt-1 flex flex-row flex-wrap justify-center w-full">
                <span
                  className="mt-1 max-w-sm whitespace-no-wrap flex-grow mr-3 mb-3 bg-blue-100 text-16px font-semibold px-12 py-3
                  text-center cursor-pointer items-center block shadow relative text-white"
                  onClick={() => document.getElementById("adolopment-form")?.scrollIntoView()}
                >
                  {t("labels.gateway_to_adaptation")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <TwitterNews /> */}
      <div id="what-can-you-do-on-this-page">
        <h1 className="pt-24 pb-16 text-center text-3xl">
          {t("labels.what_you_can_do_on_this_portal")}
        </h1>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg">
          <GuideSection sectionKey="search">{t("guideSections.search")}</GuideSection>
          <GuideSection sectionKey="list">
            <Trans i18nKey="guideSections.list">
              Check out the{" "}
              <Link className="text-whoCovid-100 underline hover:opacity-50" to="/recommendations">
                full list of COVID-19 recommendations
              </Link>{" "}
              included (updates in process) and filter by key variables
            </Trans>
          </GuideSection>
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg">
          <GuideSection sectionKey="grid">
            <Trans i18nKey="guideSections.grid">
              Check out the{" "}
              <Link className="text-whoCovid-100 underline hover:opacity-50" to="/grid">
                living map of recommendations
              </Link>{" "}
              by Population and Interventions and filter by key variables
            </Trans>
          </GuideSection>
          <GuideSection sectionKey="glossary">
            <Trans i18nKey="guideSections.glossary">
              Learn more{" "}
              <Link className="text-whoCovid-100 underline hover:opacity-50" to="/about">
                about us
              </Link>
              ,{" "}
              <Link className="text-whoCovid-100 underline hover:opacity-50" to="/glossary">
                the terminology
              </Link>{" "}
              we are using and upcoming features
            </Trans>
          </GuideSection>
        </div>
      </div>
      <div id="adolopment-form" className="px-10 pb-24 max-w-5xl mx-auto">
        {/* <AdolopmentForm recommendationId={null} /> */}
        <h1 className="pt-16 pb-16 text-center text-3xl">{t("labels.gateway_to_adaptation")}</h1>
        <h1 className="text-whoCovid-100 text-center mb-3 text-xl">
          {t("labels.adolopment")}
          <AdolopmentInstruction />
        </h1>
        <div className="mb-2 text-center">
          <AdolopmentDescription />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
