import _ from "lodash";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import { isValidLink } from "../../lib/utils";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";

export const keysToSkip = [
  "@id",
  "displayId",
  "shortTitle",
  "title",
  "agreeScore",
  "intervention",
  "population",
  "guidelineNoId",
];
export const translatableKeys = [
  "version",
  "strengthOfRecommendation",
  "directionOfTheRecommendation",
];

const getInterventionOrPopulation = (entities, key) =>
  _.map(entities, (entity) =>
    _.upperFirst(entity[key === "intervention" ? "name" : "description"])
  ).join(", ");

export const getFormattedValueFromGuideline = (t, key, value) => {
  if (_.includes(["intervention", "population"], key))
    return getInterventionOrPopulation(value, key);

  if (key === "recommendationIntent") return value.map((v) => t(`filters.${key}.${v}`)).join(", ");

  if (
    _.includes(
      ["publicationDate", "latestLiteratureSearchDate", "latestDateOfLiteratureSearch"],
      key
    )
  ) {
    if (_.isEmpty(value)) return "";
    return value !== "not_reported"
      ? new Date(value).toISOString().slice(0, 10)
      : t(`recommendation.${value}`);
  }

  if (key === "country") {
    return value.join(", ");
  }

  if (_.includes(["methodOfGradingEvidence", "gradingEvidenceMethod"], key)) {
    if (_.includes(["notGraded", "grade"], value)) {
      return t(`recommendation.fieldValues.${key}.${value}`);
    } else {
      return value;
    }
  }

  if (_.includes(translatableKeys, key)) {
    return t(`recommendation.fieldValues.${key}.${value}`);
  }

  if (_.isArray(value)) {
    return key === "source"
      ? value.join(", ")
      : value.map((v) => t(`recommendation.fieldValues.${key}.${v}`)).join(", ");
  }
  if (
    _.isBoolean(value) ||
    value === "no" ||
    value === "false" ||
    value === "yes" ||
    value === "true"
  ) {
    const valueKey = value || value === "yes" || value === "true" ? "yes" : "no";
    return t(`recommendation.fieldValues.${key}.${valueKey}`);
  }
  if (isValidLink(value)) {
    return `<a href="${value}" target="_blank" rel="noopener noreferer">${value}</a>`;
  }
  if (value === "notReported") {
    return t(`recommendation.not_reported`);
  }
  if (key === "recommendationType") {
    return !_.isEmpty(value) ? t(`filters.recommendationFormality.${value}`) : "";
  }
  return (value || "").toString();
};

export const getGuidelineValues = (t, guideline) => {
  return _.reduce(
    guideline,
    (acc, value, key) => {
      return keysToSkip.indexOf(key) !== -1 || _.isEmpty(value?.toString())
        ? acc
        : acc.concat({
            label: t(`recommendation.${key}`),
            value: getFormattedValueFromGuideline(t, key, value),
          });
    },
    []
  );
};

const SourceOfRecommendation = ({ guideline, title, toggleRef }) => {
  const { t } = useTranslation();
  const rows = useMemo(() => getGuidelineValues(t, guideline), [t, guideline]);

  return guideline ? (
    <ExpandableCard
      title={title || t("labels.source_of_recommendation")}
      tooltip={t("tooltips.sourceOfRecommendation")}
      toggleRef={toggleRef}
    >
      <h2 className="font-semibold m-3">{guideline.title}</h2>
      <CustomTable rows={rows} />
    </ExpandableCard>
  ) : null;
};

SourceOfRecommendation.propTypes = {
  guideline: guidelineShape,
  title: PropTypes.string,
};

SourceOfRecommendation.defaultProps = {
  title: null,
};

export default SourceOfRecommendation;
