import React from "react";
import { useTranslation } from "react-i18next";
import Box from "./common/Box";
import CustomTable from "./common/CustomTable";
import TEAM_ROWS from "../assets/teamList.json";

export const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-screen-lg relative mx-auto mb-6">
      <div className="w-full flex flex-row align-center justify-between mb-2">
        <h1 className={`pl-6 pt-2 text-2xl text-whoCovid-100 font-normal`}>
          {t("labels.investigators")}
        </h1>
      </div>
      <Box className="p-5 bg-white mb-6">
        <div className="w-full p-4 flex flex-row flex-wrap justify-center align-center">
          <CustomTable
            className="flex-grow"
            valueClassName="py-1 px-2 sm:py-3 sm:px-3"
            rows={TEAM_ROWS}
          />
        </div>
      </Box>
    </div>
  );
};
