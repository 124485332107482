import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { guidelineShape } from "../../lib/data_shapes";

interface IGuidelineHeader {
  guideline: {
    "@id": string;
    title: string;
    source: string;
  };
}

const GuidelineHeader = ({ guideline }: IGuidelineHeader) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-5 rounded-t-lg">
      <Link to={`/guideline/${guideline["@id"]}`}>
        <h1 className="text-lg  font-semibold text-teal-600">{guideline.title}</h1>
      </Link>
      <div className="mt-2 text-xs text-teal-600">
        {t("labels.source")}: {guideline.source}
      </div>
    </div>
  );
};

GuidelineHeader.propTypes = {
  guideline: guidelineShape,
};

export default GuidelineHeader;
