import React, { FC } from "react";
import _ from "lodash";
import Box from "../common/Box";
import { useTranslation } from "react-i18next";

const TABLE_KEYS = ["age_filter", "world_regions"];

interface IGlossaryItem {
  title: string;
  value: any;
  glossaryKey: string;
  columns?: string[];
}

const GlossaryItem: FC<IGlossaryItem> = ({ glossaryKey, columns, title, value }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-12 glossary-item last:mb-0">
      <div className="font-semibold text-whoCovid-100 mb-1">{title}</div>
      {_.isString(value) ? (
        <div className="mb-12" dangerouslySetInnerHTML={{ __html: value }} />
      ) : glossaryKey === "_4" ? (
        <>
          <div className="tailwind-hidden md:block">
            <table className="glossary">
              <thead>
                <tr>
                  <th>{t("labels.implications_for")}</th>
                  <th>{t("labels.strong_recommendation")}</th>
                  <th>{t("labels.conditional_recommendation")}</th>
                </tr>
              </thead>
              <tbody>
                {_.map(value, (v, key) => (
                  <tr key={key}>
                    <td className="text-center">{v.title}</td>
                    <td>{v.strong_recommendation}</td>
                    <td>{v.conditional_recommendation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="block md:hidden">
            <div className="font-semibold text-whoCovid-100 my-3">
              {t("labels.implications_for")}
            </div>
            {_.map(value, (v, key) => {
              return (
                <div className="border-b border-covid-100 pb-4 mt-4" key={key}>
                  <div className="text-xl font-semibold my-3">{v.title}</div>
                  <div className="text-whoCovid-100 underline">
                    {t("labels.strong_recommendation")}
                  </div>
                  <div className="mb-3">{v.strong_recommendation}</div>
                  <div className="text-whoCovid-100 underline">
                    {t("labels.conditional_recommendation")}
                  </div>
                  <div>{v.conditional_recommendation}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : _.includes(TABLE_KEYS, glossaryKey) ? (
        <div className="block">
          <table className="glossary">
            <thead>
              <tr>
                {_.map(columns, (column) => (
                  <th key={column}>{t(`labels.${column}`)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(value, (v, key) => (
                <tr key={key}>
                  {_.map(columns, (column) => (
                    <td key={column} dangerouslySetInnerHTML={{ __html: v[column] }} />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

const Glossary = () => {
  const { t } = useTranslation();

  const glossary = t("glossary", { returnObjects: true });

  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <div className="w-full flex flex-row align-center justify-between mb-5 mr-5">
        <h1 className="pl-6 pt-2 text-2xl text-whoCovid-100 font-normal">{t(`labels.glossary`)}</h1>
      </div>
      <Box className="p-5 bg-white mb-6">
        {_.map(
          glossary,
          (glossaryItem: { title: string; value: any; columns?: string[] }, key: string) => (
            <GlossaryItem
              columns={glossaryItem.columns || []}
              key={key}
              glossaryKey={key}
              title={glossaryItem.title}
              value={glossaryItem.value}
            />
          )
        )}
      </Box>
    </div>
  );
};

export default Glossary;
