import FileSaver from 'file-saver';
import _ from 'lodash';
import { DependencyList, EffectCallback, Reducer, useEffect, useReducer, useRef } from 'react';
import { getFormattedValueFromGuideline } from '../components/recommendation/SourceOfRecommendation';
import { t } from "../i18n";

export const isDebugMode = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
};

export const isTestEnv = () => {
  // TODO change hostname to correct when test enviroment is prepared
  return window.location.hostname === "who-covid.test.evidenceprime.com";
};

// TODO configure later
// export const sendReportToSentry = error => Raven.captureException(error);
export function useSetState<U>(initialState: U) {
  const reducer = (state: U, toMerge: Partial<U> | ((state: U) => U)): U =>
    _.isFunction(toMerge) ? toMerge(state) : { ...state, ...toMerge };
  return useReducer<Reducer<U, Partial<U> | ((state: U) => U)>>(reducer, initialState);
}

export const linkToKnowledgebaseAdolopment = () => {
  return isTestEnv()
    ? "https://kb.covid19.test.evidenceprime.com/"
    : "https://kb.covid19.evidenceprime.ca/";
};


export function useDidUpdateEffect(fn: EffectCallback, depsList?: DependencyList) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [fn, ...(depsList ?? [])]);  // eslint-disable-line react-hooks/exhaustive-deps
}

export function stripHtml(html: string) {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const isValidLink = (maybeUrl: string): boolean => {
  try {
    const url = new URL(maybeUrl);
    return _.includes(["http:", "https:"], url.protocol);
  } catch (_ignored) {
    return false;
  }
};

export const isValidEmail = (maybeEmail: string): boolean => {
  const regExp =
    /^(([A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+)*)|("[\u0020-\u007E]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(maybeEmail);
};

const EXPORT_KEY_MAPPING = {
  'recommendation': 'recommendationText',
  "guidelineCitation": 'fullReferenceCitation',
  "gradeStrength": "strengthOfRecommendation",
  "recommendationDirection": "directionOfTheRecommendation",
  "ages": "populationAge",
  "population": "population",
  "intervention": "intervention",
  "recommendationIntent": "recommendationIntent",
  "recommendationFormality": "recommendationType",
  "guidelineTitle": "title",
  "guidelinePublicationDate": "publicationDate",
  "guidelineSourceDocumentLink": "linkToSourceDocument",
  "guidelineWhoWorldRegion": "whoRegion",
  "guidelineLatestLiteratureSearchDate": "latestDateOfLiteratureSearch",
  "guidelineGradingEvidenceMethod": "methodOfGradingEvidence",
  "guidelineField": "focus",
}

export const exportRecommendationsToCsv = (recommendations: object[], fields: string[]): void => {
  const recommendationsArray = _.map(recommendations, (recommendation) => {
    //@ts-ignore
    const recommendationWithNewKeys = _.mapKeys(recommendation, (_, key) => EXPORT_KEY_MAPPING[key] ?? key)
    const recommendationWithParsedValues = _.reduce(_.pick(recommendationWithNewKeys, fields), (acc, value, key) => {
      //@ts-ignore
      acc[key] = stripHtml(getFormattedValueFromGuideline(t, key, value));
      return acc;
    }, {})
    return recommendationWithParsedValues
  })

  let csv = _.map(fields, (field) => `"${t(`labels.${field}`)}"`).join(',') + '\n';
  csv += _.reduce(recommendationsArray, (rows, recommendation) => {
    //@ts-ignore
    rows.push(_.map(fields, (field) => `"${(recommendation[field] || "").replace(/"/g, '\'').replace(/\s/g, ' ').trim()}"`).join(','));
    return rows;
  }, []).join('\n');

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(blob, "export.csv");
}