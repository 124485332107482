import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";

const Card: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
  return <div className={`card rounded-lg p-5 bg-white ${className}`}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: "",
};

export default Card;
