import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const DisclaimerShort = ({ withSeeMoreBtn, linkColor }) => {
  const { t } = useTranslation();
  return (
    <>
      <Trans i18nKey="app.disclaimerSnippet">
        Please read this disclaimer ("disclaimer") carefully before using the COVID19
        Recommendations.
      </Trans>{" "}
      {withSeeMoreBtn && (
        <Link className={`${linkColor} hover:opacity-50`} to="/about">
          {t("labels.see_more")}
        </Link>
      )}
    </>
  );
};

DisclaimerShort.propTypes = {
  withSeeMoreBtn: PropTypes.bool,
  linkColor: PropTypes.string,
};

DisclaimerShort.defaultProps = {
  withSeeMoreBtn: false,
  linkColor: "text-whoCovid-100",
};
