import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { recommendationDirectionType } from "../../lib/data_shapes";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import Box from "../common/Box";
import RecommendationIcon from "../common/RecommendationIcon";

interface IRecommendationStrengthContent {
  className?: string;
  gradeStrength: string;
  recommendationDirection: string;
}

const RecommendationStrengthContent = ({
  className,
  gradeStrength,
  recommendationDirection,
}: IRecommendationStrengthContent) => {
  const { t } = useTranslation("");

  const recommendationType = useMemo(() => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    return getRecommendationText(t, gradeStrength);
  }, [t, gradeStrength]);

  return (
    <div className={className}>
      <RecommendationIcon
        textClasses="font-semibold"
        recommendationType={recommendationType}
        recommendationText={recommendationText}
      />
    </div>
  );
};

interface IRecommendationStrength {
  noBox: boolean;
  className?: string;
  gradeStrength: string;
  recommendationDirection: string;
}

const RecommendationStrength = ({
  className,
  noBox,
  gradeStrength,
  recommendationDirection,
}: IRecommendationStrength) => {
  const { t } = useTranslation("");

  const recommendation = useMemo(() => {
    return (
      <RecommendationStrengthContent
        className={className}
        gradeStrength={gradeStrength}
        recommendationDirection={recommendationDirection}
      />
    );
  }, [className, gradeStrength, recommendationDirection]);

  return gradeStrength ? (
    noBox ? (
      recommendation
    ) : (
      <Box
        tooltip={t("tooltips.strength")}
        className="p-5 mt-0 mb-1 text-left mr-2 flex-grow-0"
        contentClassName="font-semibold"
        label={t("recommendation.recommendation_strength")}
      >
        {recommendation}
      </Box>
    )
  ) : null;
};

RecommendationStrength.propTypes = {
  className: PropTypes.string,
  noBox: PropTypes.bool,
  gradeStrength: PropTypes.string,
  recommendationDirection: recommendationDirectionType,
};

RecommendationStrength.defaultProps = {
  className: "",
  noBox: false,
  gradeStrength: null,
  recommendationDirection: null,
};

export default RecommendationStrength;
