import React from "react";
import { useTranslation } from "react-i18next";
import { TCertaintyValue } from "../../lib/types";
import CertaintyCircles from "./CertaintyCircles";

interface ICertainty {
  signSize: "small" | "regular";
  value: TCertaintyValue;
}

const Certainty = ({ signSize, value }: ICertainty) => {
  const { t } = useTranslation();

  const signClassNames = `quality-sign ${signSize === "small" ? "quality-sign--small" : ""}`;

  return (
    <div className="certainty flex flex-row items-center">
      <div className="whitespace-no-wrap">
        <CertaintyCircles value={value} signClassName={signClassNames} />
      </div>
      <div className="ml-2 whitespace-no-wrap" style={{ lineHeight: "15px" }}>
        {t(`recommendation.certainty.${value}`)}
      </div>
    </div>
  );
};

export default Certainty;
