import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import additionalGuidanceIcon from "../../assets/additional-guidance.svg";
import goodPracticeStatementIcon from "../../assets/good-practice-statement.svg";
import regularRecommendationIcon from "../../assets/regular-recommendation.svg";
import researchRecommendationIcon from "../../assets/research-recommendation.svg";
import adaptedIcon from "../../assets/adapted-blue.svg";

const RECOMMENDATION_FORMALITY_TO_ICONS = {
  gps: goodPracticeStatementIcon,
  informal: additionalGuidanceIcon,
  formal: regularRecommendationIcon,
  research: researchRecommendationIcon,
};

export const RECOMMENDATION_FORMALITIES = ["formal", "gps", "informal", "research"];
const TYPES = ["guideline", "recommendation"];
const SIZES = ["large", "small"];

const TypeHeader = ({ adapted, className, recommendationFormality, size, type }) => {
  const { t } = useTranslation();

  return _.includes(TYPES, type) ? (
    <div className={`flex items-center ${className}`}>
      {type !== "guideline" && (
        <Icon recommendationFormality={recommendationFormality} size={size} />
      )}
      <Title
        title={t(`labels.${type === "guideline" ? type : recommendationFormality}_recommendation`)}
        size={size}
      />
      {adapted && (
        <img data-tip src={adaptedIcon} className="ml-2" alt="adapted" data-for="adapted" />
      )}
    </div>
  ) : null;
};

TypeHeader.propTypes = {
  adapted: PropTypes.bool,
  className: PropTypes.string,
  recommendationFormality: PropTypes.oneOf(RECOMMENDATION_FORMALITIES),
  size: PropTypes.oneOf(SIZES),
  type: PropTypes.oneOf(TYPES),
};

TypeHeader.defaultProps = {
  adapted: false,
  className: "",
  recommendationFormality: null,
  size: "large",
};

export const Title = ({ className, title, size }) => {
  return (
    <div className={`${size === "large" ? "text-xl" : "text-xs font-semibold "} ${className}`}>
      {title}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(SIZES),
  className: PropTypes.string,
};

Title.defaultProps = {
  className: "",
};

export const Icon = ({ recommendationFormality, size }) => {
  return _.includes(RECOMMENDATION_FORMALITIES, recommendationFormality) ? (
    <img
      src={RECOMMENDATION_FORMALITY_TO_ICONS[recommendationFormality]}
      className={size === "large" ? "w-8 mr-3" : "w-5 mr-2"}
      alt=""
    />
  ) : null;
};

Icon.propTypes = {
  recommendationFormality: PropTypes.oneOf(RECOMMENDATION_FORMALITIES),
  size: PropTypes.oneOf(SIZES),
};

Icon.defaultProps = {
  size: "large",
  informal: false,
};

export default TypeHeader;
