import _ from "lodash";

export function params2filters(params) {
  const filters = {};
  for (const [key, value] of params.entries()) {
    filters[key] = value;
  }
  return filters;
}

function hex(c) {
  var s = "0123456789abcdef";
  var i = parseInt(c);
  if (i === 0 || isNaN(c)) return "00";
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
export function rgb2hex(rgb) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s) {
  return s.charAt(0) === "#" ? s.substring(1, 7) : s;
}

/* Convert a hex string to an RGB triplet */
function convertToRGB(hex) {
  var color = [];
  color[0] = parseInt(trim(hex).substring(0, 2), 16);
  color[1] = parseInt(trim(hex).substring(2, 4), 16);
  color[2] = parseInt(trim(hex).substring(4, 6), 16);
  return color;
}

export function generateColorMap(colorStart, colorMiddle, colorEnd, colorCount) {
  // The beginning of your gradient
  var start = convertToRGB(colorStart);
  // The middle of your gradient
  var middle = convertToRGB(colorMiddle);
  // The end of your gradient
  var end = convertToRGB(colorEnd);

  // The number of colors to compute
  var len = Math.ceil(colorCount / 2);

  //Alpha blending amount
  let alpha = 0.0;

  var part1 = [];

  for (let i = 0; i < len; i++) {
    let c = [];

    alpha += 1.0 / len;

    c[0] = start[0] * alpha + (1 - alpha) * middle[0];
    c[1] = start[1] * alpha + (1 - alpha) * middle[1];
    c[2] = start[2] * alpha + (1 - alpha) * middle[2];

    part1.push(rgb2hex(c));
  }
  part1.reverse();
  alpha = 0;

  var part2 = [];

  for (let i = 0; i < len; i++) {
    let c = [];
    alpha += 1.0 / len;

    c[0] = middle[0] * alpha + (1 - alpha) * end[0];
    c[1] = middle[1] * alpha + (1 - alpha) * end[1];
    c[2] = middle[2] * alpha + (1 - alpha) * end[2];

    part2.push(rgb2hex(c));
  }

  part2.reverse();
  return part1.concat(part2);
}

export function hex2Hsl(hexColor) {
  let r = parseInt(hexColor.substr(1, 2), 16) / 255; // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
  let g = parseInt(hexColor.substr(3, 2), 16) / 255;
  let b = parseInt(hexColor.substr(5, 2), 16) / 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

export function hsl2hex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getFilterOptionName = (t, key, option) => {
  if (option.name === "any") {
    return t("filters.any");
  }

  switch (key) {
    case "coexistingCondition":
    case "source":
      return `${option.name} (${option.count})`;
    default:
      const filterName = isNaN(+option.name) ? t(`filters.${key}.${option.name}`) : option.name;
      return `${filterName} (${option.count})`;
  }
};

export const getRecommendationType = (recommendationDirection, gradeStrength) => {
  // WiP, Bart need to be discuss with others how to handle different cases
  switch (recommendationDirection) {
    case "positive":
      switch (gradeStrength) {
        case "strong":
          return "strong-for";
        case "conditional":
        case "weak":
          return "suggest-for";
        default:
          return "";
      }
    case "negative":
      switch (gradeStrength) {
        case "strong":
          return "recommend-against";
        case "conditional":
        case "weak":
          return "suggest-against";
        default:
          return "";
      }
    case "neutral":
      return "suggest-either";
    default:
      return "";
  }
};

export const getRecommendationText = (t, gradeStrength) => {
  const strengthText = t(`recommendation.strength.${gradeStrength}`);
  return _.includes(["not_stated", "unclear", "other_explanation_in_notes"], gradeStrength)
    ? strengthText
    : `${strengthText}`.trim();
};

export const questionsHasCommonStrengthAndCertainty = (questions) => {
  if (questions.length === 1) return true;
  const baseQuestion = _.first(questions);
  const { gradeStrength: baseStrength, gradeCertaintyOfEvidence: baseCertainty } = baseQuestion;

  return !_.some(
    questions,
    ({ gradeStrength, gradeCertaintyOfEvidence }) =>
      gradeStrength !== baseStrength || gradeCertaintyOfEvidence !== baseCertainty
  );
};

export const parseTable = (tableHtmlStr) => {
  if (_.isEmpty(tableHtmlStr)) return null;
  const tableHtml = _.first(
    new DOMParser().parseFromString(tableHtmlStr, "text/html").getElementsByTagName("table")
  );

  const rows = tableHtml.getElementsByTagName("tr");
  const headerCells = _.first(rows).getElementsByTagName("th");
  const body = _.compact(
    _.map(rows, (row) => {
      const cells = row.getElementsByTagName("td");
      if (_.some(cells, (cell) => !_.isEmpty(cell.innerText.trim()))) {
        return _.map(cells, (cell) => cell.innerText);
      }
    })
  );

  return _.map(body, (bodyRow) => {
    return _.reduce(
      bodyRow,
      (acc, cell, index) => {
        acc[_.camelCase(headerCells[index].innerText)] = cell;
        return acc;
      },
      {}
    );
  });
};
