import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import whoLogo from "../../assets/who-logo-white.svg";
import { glossaryLink } from "../../lib/constants";
import { DisclaimerShort } from "../common/DisclaimerShort";
import { LinkExternalPage } from "../common/HamburgerMenu";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-blue-100 py-5">
      <div className="max-w-screen-md text-center mx-auto text-white text-xs mb-6">
        <DisclaimerShort withSeeMoreBtn linkColor="text-white" />
      </div>
      <div className="max-w-screen-lg mx-auto">
        <div className="md:flex md:flex-row">
          <div>
            <div className="py-5 mx-5 text-white">
              <Link className="block py-2 hover:opacity-50" to="/about">
                {t("labels.aboutRecMap")}
              </Link>
              <Link className="block py-2 hover:opacity-50" to="/glossary">
                {t("labels.glossary")}
              </Link>
              <a
                className="block py-2 hover:opacity-50"
                href={glossaryLink}
                target="blank"
                rel="noopener noreferrer"
              >
                {t("labels.grade_handbook_glossary")}
              </a>
            </div>
          </div>
          <div>
            <div className="py-5 mx-5 text-white">
              <LinkExternalPage
                className="block py-2 md:ml-6 text-white hover:opacity-50"
                href="https://apps.who.int/iris/browse?authority=COVID-19&type=mesh"
                text={t("labels.who_global_public_health")}
              />
              <LinkExternalPage
                className="block py-2 md:ml-6 text-white hover:opacity-50"
                href="https://iris.paho.org/browse?type=subject&value=COVID-19"
                text={t("labels.paho_covid19_database")}
              />
              <LinkExternalPage
                className="block py-2 md:ml-6 text-white hover:opacity-50"
                href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/global-research-on-novel-coronavirus-2019-ncov"
                text={t("labels.who_covid_database")}
              />
              <LinkExternalPage
                className="block py-2 md:ml-6 text-white hover:opacity-50"
                href="https://covid19.recmap.org/"
                text={t("labels.international_organizations_covid_recmap")}
              />
            </div>
          </div>
        </div>
      </div>

      <Link className="block my-4" to="/">
        <img src={whoLogo} className="h-16 mx-auto" alt="logo" />
      </Link>
    </footer>
  );
};

export default Footer;
