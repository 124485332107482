import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import EpLogo from "../../assets/ep-logo.svg";
import GradeProLogo from "../../assets/gradepro-logo.svg";
import McMasterLogo from "../../assets/mcmaster-logo.svg";
import { glossaryLink } from "../../lib/constants";
import { useHandleClickOutside } from "../../lib/custom_hooks";
import { DisclaimerShort } from "./DisclaimerShort";

export const LinkExternalPage = ({
  text,
  href,
  className,
}: {
  text: string;
  href: string;
  className: string;
}) => (
  <a
    className={className || "text-xs mt-1 block text-left text-whoCovid-100 hover:underline"}
    href={href}
    target="blank"
    rel="noopener noreferer"
  >
    {text}
  </a>
);

LinkExternalPage.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkExternalPage.defaultProps = {
  className: "",
};

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useHandleClickOutside(menuRef, () => setMenuOpen(false));

  const goTo = (page: string) => () => {
    history.push(page);
    setMenuOpen(false);
  };

  return (
    <div ref={menuRef} className="h-6 relative ml-auto mt-1 self-start justify-end hamburger-menu">
      <button
        className={`open-menu block ${menuOpen ? "fixed open" : "absolute closed"}`}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      />

      {menuOpen && (
        <div className="menu-container">
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow">
              <div className="py-5 mx-5 glossary-links">
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/about")}
                >
                  {t(`app.aboutCovid19`)}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/guidance-on-implementation")}
                >
                  {t("labels.guidanceOnImplementation")}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/glossary")}
                >
                  {t("labels.glossary")}
                </button>
                <a
                  className="block py-2 font-semibold hover:opacity-50"
                  href={glossaryLink}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.grade_handbook_glossary")}
                </a>
              </div>
              <div className="px-6 text-xs mt-1">
                <DisclaimerShort withSeeMoreBtn />
              </div>

              <div className="px-6">
                <div className="mt-8 mb-3 text-xs font-semibold">{t("labels.visit_also")}</div>
                <LinkExternalPage
                  href="https://apps.who.int/iris/browse?authority=COVID-19&type=mesh"
                  text={t("labels.who_global_public_health")}
                />
                <LinkExternalPage
                  href="https://iris.paho.org/browse?type=subject&value=COVID-19"
                  text={t("labels.paho_covid19_database")}
                />
                <LinkExternalPage
                  href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/global-research-on-novel-coronavirus-2019-ncov"
                  text={t("labels.who_covid_database")}
                />
                <LinkExternalPage
                  href="https://covid19.recmap.org/"
                  text={t("labels.international_organizations_covid_recmap")}
                />
              </div>
              <div className="logos flex flex-col flex-grow justify-end">
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://www.mcmaster.ca/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={McMasterLogo} alt="McMaster" />
                </a>
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href="https://evidenceprime.com/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={EpLogo} alt="EvidencePrime" />
                </a>
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://gradepro.org/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={GradeProLogo} alt="GRADEpro" />
                </a>
              </div>
            </div>

            <div className="text-center sm:text-left">
              <button className="close-menu-arrow" onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
