import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const groups = [
  "all",
  "infection_prevention_control",
  "vaccination",
  "screening",
  "diagnosis",
  "clinical_management",
  "prognosis",
  "emergency_preparedness",
  "health_systems_and_service",
];

interface IGroupFilter {
  current: string;
  onGroupClick: (key: string) => void;
}

const GroupFilter = ({ current, onGroupClick }: IGroupFilter) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="mr-2 md:pr-10 relative ml-2 md:mr-0">
      <div
        className="
        absolute
        w-full
        -mt-5
        md:mt-0
        md:relative
        md:overflow-hidden
        md:rounded-full
        md:leading-none
        group-tabs
        md:flex
        md:flex-row
        md:justify-between
        "
      >
        <button
          className={`
          w-full
          md:hidden
          group-tab
          group-tab-toggle
          text-center
          px-5
          py-2
          flex-grow
          current-blue
          text-white
          flex
          items-center
          ${expanded ? "expanded" : "collapsed"}
        `}
          onClick={() => setExpanded(!expanded)}
        >
          {t(`groups.${current}`)}
        </button>
        {groups.map((group) => (
          <div
            className={`group-tab md:flex-grow
            ${current === group ? "current tailwind-hidden md:block" : ""}
            ${expanded ? "block group-expanded" : "tailwind-hidden"}
            text-white md:flex md:items-center`}
            key={group}
          >
            <button
              className="h-12 px-5 w-full"
              onClick={() => {
                onGroupClick(group);
                setExpanded(false);
              }}
            >
              {t(`groups.${group}`)}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

GroupFilter.propTypes = {
  current: PropTypes.string,
  onGroupClick: PropTypes.func.isRequired,
};

GroupFilter.defaultProps = {
  current: "all",
};

export default GroupFilter;
