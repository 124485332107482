import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

interface IInstructionModal {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const InstructionModal: FC<IInstructionModal> = ({ isOpen, onClose, title, children }) => {
  return (
    <Modal modalSize="auto" isOpen={isOpen} onClose={onClose} closeButton={true}>
      <div className="max-w-96 p-3">
        <h1 className="text-xl mb-6 font-semibold">{title}</h1>
        {children}
      </div>
    </Modal>
  );
};

InstructionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InstructionModal;
