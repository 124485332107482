export const contactMail = "canada@cochrane.org";
export const emlMainPageLink = "https://list.essentialmeds.org/";
export const evidenceprimeLink = "https://evidenceprime.com";
export const glossaryLink = "https://gdt.gradepro.org/app/handbook/handbook.html#h.buaodtl66dyx";
export const loveMainPageLink = "https://iloveevidence.com/";
export const niphLink = "https://www.nornesk.no/forskningskart/NIPH_mainMap.html";
export const surveyLink = "https://www.surveymonkey.com/r/9WBR6JY";
export const twitterLink = "https://twitter.com/eCOVID19RecMap";
export const mcMasterLink = "https://www.mcmaster.ca/";
export const cochraneCanadaLink = "https://canada.cochrane.org/";
export const ginLink = "https://g-i-n.net/";
export const linkToPrivacyPolicy = "https://gradepro.org/privacy.html/";
export const covidLink = "https://covid19.recmap.org";
export const supportLink = "support@evidenceprime.com";
export const showBanner = false;
export const FIELDS_TO_EXPORT = [
  "title", // guideline title
  "fullReferenceCitation", // citation of the guideline the recommendation comes from
  "linkToSourceDocument",
  "methodOfGradingEvidence",
  "whoRegion",
  "recommendationText",
  "recommendationIntent",
  "focus",
  "strengthOfRecommendation",
  "directionOfTheRecommendation",
  "recommendationType", // formal, research, informal, gps
  "publicationDate",
  "latestDateOfLiteratureSearch",
  "intervention",
  "population",
  "populationAge",
];
