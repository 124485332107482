import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import TypeHeader, { Title } from "../common/TypeHeader";
import GuidelineHeader from "./GuidelineHeader";
import { RecommendationsList } from "./RecommendationGroupsList";
import SourceOfRecommendation from "./SourceOfRecommendation";

const Guideline = ({ data }) => {
  const { guideline, recommendations } = data;
  const { t } = useTranslation();
  return (
    <div>
      <TypeHeader className="text-whoCovid-100 mb-3 mt-1" type="guideline" />
      <GuidelineHeader guideline={guideline} />
      <SourceOfRecommendation title={t("labels.about_guideline")} guideline={guideline} />
      <Title title={t("labels.recommendations")} size="large" className="text-whoCovid-100 mt-8" />
      <RecommendationsList recommendations={recommendations} />
    </div>
  );
};

Guideline.propTypes = {
  data: PropTypes.shape({
    "@id": PropTypes.string.isRequired,
    guideline: guidelineShape,
    recommendations: PropTypes.arrayOf(PropTypes.object),
  }),
};

Guideline.defaultProps = {
  data: {
    "@id": null,
    guideline: null,
    recommendations: [],
  },
};

export default Guideline;
