import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Box from "../common/Box";
import Certainty from "../common/Certainty";

interface IRecommendationCertainty {
  noBox: boolean;
  gradeCertaintyOfEvidence:
    | "low"
    | "very_low"
    | "moderate"
    | "high"
    | "other_explanation_in_notes"
    | "not_stated"
    | "unclear";
  gradeCertaintyOfEvidenceLabel: string;
  labelClassName: string;
  signSize: "regular" | "small";
}

const RecommendationCertainty = ({
  noBox,
  gradeCertaintyOfEvidence,
  gradeCertaintyOfEvidenceLabel,
  labelClassName,
  signSize,
}: IRecommendationCertainty) => {
  const { t } = useTranslation("");

  const label = gradeCertaintyOfEvidenceLabel
    ? t(`recommendation.certainty.${gradeCertaintyOfEvidenceLabel}`)
    : t(`labels.certainty_of_evidence`);

  return gradeCertaintyOfEvidence ? (
    noBox ? (
      <div className="mt-1">
        <div className={`font-semibold ${labelClassName}`}>{label}</div>
        <Certainty signSize={signSize} value={gradeCertaintyOfEvidence} />
      </div>
    ) : (
      <Box
        tooltip={t("tooltips.certainty_of_evidence")}
        className="p-5 mb-1 text-left flex-grow-0"
        label={label}
      >
        <div className="mt-1">
          <Certainty signSize={signSize} value={gradeCertaintyOfEvidence} />
        </div>
      </Box>
    )
  ) : null;
};

RecommendationCertainty.propTypes = {
  noBox: PropTypes.bool,
  labelClassName: PropTypes.string,
  gradeCertaintyOfEvidenceLabel: PropTypes.string,
  gradeCertaintyOfEvidence: PropTypes.string,
  signSize: PropTypes.oneOf(["regular", "small"]),
};

RecommendationCertainty.defaultProps = {
  noBox: false,
  labelClassName: "",
  gradeCertaintyOfEvidence: null,
  gradeCertaintyOfEvidenceLabel: "",
  signSize: "regular",
};

export default RecommendationCertainty;
