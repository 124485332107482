import _ from "lodash";
import PropTypes from "prop-types";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import UAParser from "ua-parser-js";
import { useDidUpdateEffect } from "../../lib/utils";
import ExpandableCard from "../common/ExpandableCard";

const EtDPrintout: FC<{ etdPrintout: string }> = ({ etdPrintout }) => {
  const iframeRef = useRef(null);

  const getHTMLSource = useCallback(() => (!_.isEmpty(etdPrintout) ? etdPrintout : null), [
    etdPrintout,
  ]);

  const adjustHeightAndStyles = useCallback(() => {
    const setHeightAndStyles = () => {
      // @ts-ignore
      const iframeDoc = iframeRef.current?.contentWindow.document;
      if (!iframeDoc) return;
      // leave 25px to display properly in Firefox
      // @ts-ignore
      iframeRef.current.height = `${iframeDoc.body.scrollHeight + 50}px`;
    };

    // Fired twice - on some browsers after 300 ms, height is still not calculated inside
    // after 300 ms. Happened on Firefox on Bart's Mac, cannot reproduce myself
    _.delay(setHeightAndStyles, 300);
    _.delay(setHeightAndStyles, 1000);
  }, []);

  const loadSource = useCallback(() => {
    const parser = UAParser();
    const { browser } = parser;

    const template = getHTMLSource();

    switch (browser.name) {
      case "IE":
        // @ts-ignore
        const doc = iframeRef.current?.contentWindow.document;
        doc.open();
        doc.write(template);
        doc.close();
        break;
      case "Edge":
        const blob = new Blob([template ?? ""], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        // @ts-ignore
        iframeRef.current.src = blobUrl;
        break;
      default:
        // @ts-ignore
        iframeRef.current.setAttribute("srcDoc", template);
    }

    adjustHeightAndStyles();
  }, [getHTMLSource, adjustHeightAndStyles]);

  useEffect(() => {
    loadSource();
  }, [loadSource]);

  useDidUpdateEffect(() => {
    loadSource();
  }, [loadSource]);

  return (
    // @ts-ignore
    <iframe title="iEtD" ref={iframeRef} seamless="seamless" style={{ width: "100%", border: 0 }} />
  );
};

EtDPrintout.propTypes = { etdPrintout: PropTypes.string.isRequired };

const EtdSection: FC<{ etdPrintout: string; toggleRef: HTMLDivElement }> = ({
  etdPrintout,
  toggleRef,
}) => {
  const { t } = useTranslation();

  return !_.isEmpty(etdPrintout) ? (
    <ExpandableCard title={t("labels.etd")} contentClassName="px-1" toggleRef={toggleRef}>
      <EtDPrintout etdPrintout={etdPrintout} />
    </ExpandableCard>
  ) : null;
};

EtdSection.propTypes = {
  etdPrintout: PropTypes.string.isRequired,
};

export default EtdSection;
